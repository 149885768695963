const dev = false

if (dev) {
  var BASE_URL = "http://127.0.0.1:8001"
} else {
  var BASE_URL = "https://llgdfblv54.execute-api.us-east-1.amazonaws.com/api"
}

export const FEEDBACK_API_URL = `${BASE_URL}/feedback/feedback`
export const HEALTHCHECK_API_URL = `${BASE_URL}/healthcheck`
export const SCHEMA_CONFIG_API_URL = `${BASE_URL}/configs`
export const TENANT_CONFIG_URL = `${BASE_URL}/configs/tenants`
export const USER_API_URL = `${BASE_URL}/users`
export const USER_PROFILE_API_URL = `${BASE_URL}/users/profile`
export const TENANT_API_URL = `${BASE_URL}/tenants`