import React, { useEffect, useState } from 'react';
import "../style.css";
import { TENANT_API_URL } from "../constants/urls"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import generateRandomString from '../utils/GenerateRandomString';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { useCustomStorage } from '../hooks/useCustomStorage';
import CreateTenantModal from "./CreateTenantModal"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: 5,
    outline: null
};

function TenantTab(){
    const [tenantData, setTenantData] = useState([])
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(-1)
    const [isLoading, setIsLoading] = useState(false)
    const [authToken, setAuthToken] = useCustomStorage("acrm_auth_token")
    const [createTenantModalOpen, setCreateTenantModalOpen] = useState(false)
    useEffect(() => {
      // Do the GET API call
      setIsLoading(true)
      fetch(`${TENANT_API_URL}?product=ACRM`, {
        method: "GET",
        headers: {
          "x-api-key": authToken
        }
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Something went wrong")
        }
        return response.json()
      })
      .then((response)=>{
        // go through the tenants and set their values 
        console.log("tenant request res", response)
        // console.log("tenants", response)
        setTenantData(response)
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
        toast.error("Something Went Wrong")
        setIsLoading(false)
      })
    }, [])


    const handleUpdateTenantData = (data) => {
      // setHasChanges(true)
      setTenantData(data)
    }
    const handleDeleteTenant = (index) => {
      setIsLoading(true)
        fetch(`${TENANT_API_URL}?product=ACRM`, {
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                "x-api-key": authToken
            },
            body: JSON.stringify({ip: tenantData[deleteIndex].ip})
        })
        .then((response) => {
            if(response.status !== 200){
            throw new Error("Something went wrong")
            }
            return response.json()
        })
        .then((response) => {
            console.log("delete tenant response", response)
            setIsLoading(false)
            handleUpdateTenantData(tenantData.filter((tenant, i) => i !== index))
            toast.success(`Tenant Deleted successfully!`)
        })
        .catch((err) => {
            console.log(err)
            toast.error("Something Went wrong")
            setIsLoading(false)
        })
    }
    return (
      <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        {
          isLoading ? (
            <div style={{display: "flex", alignItems: "center", height: "80vh"}}>
                <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              {
                deleteIndex > -1 && (
                  <Dialog
                      open={openDeletePopup}
                      onClose={() => setOpenDeletePopup(false)}
                  >
                      <DialogTitle id="alert-dialog-title">
                          Delete Tenant "{tenantData[deleteIndex]?.tenantid}"?
                      </DialogTitle>
                      <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                          Are you sure you want to delete the tenant "{tenantData[deleteIndex]?.tenantid}"?
                      </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                      <Button 
                          onClick={() =>{
                              handleDeleteTenant(deleteIndex)
                              setOpenDeletePopup(false)
                          }}
                      >
                          Yes
                      </Button>
                      <Button onClick={() => setOpenDeletePopup(false)} autoFocus>
                          No
                      </Button>
                      </DialogActions>
                  </Dialog>
                )
              }
              {
                tenantData.map((tenant, index) => {
                  return (
                    <div
                      key={tenant.uuid}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                      }}
                    >
                      <Accordion 
                          style={{
                              padding: "0 1rem",
                              width: "90%",
                              paddingBottom: "0"
                          }}
                      >
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              style={{height: "50px"}}
                          >
                          <Typography sx={{fontWeight: "bold"}}>{tenant.tenantid}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                              style={{paddingBottom: "2rem", width: "100%"}}
                          >
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}> 
                              <div> ID: {tenant.tenantid} </div>
                              <div> IP: {tenant.ip}</div>
                            </div>

                          </AccordionDetails>

                      </Accordion>
                      <button
                          style={{
                              width: "5%",
                              backgroundColor: "#f44336",
                              height: "50px",
                              padding: "0.2rem 0"
                          }}
                          onClick={() => {
                            setDeleteIndex(index)
                            setOpenDeletePopup(true)
                          }}
                      >
                          <DeleteOutlinedIcon />
                      </button>
                    </div>
                  )
                })
              }
              <button
                  style={{
                      marginTop: "1rem",
                      width: "100%",
                      fontSize: "1rem",
                      backgroundColor: "#4CAF50"
                  }}
                  onClick={() => setCreateTenantModalOpen(true)}
              >
                  Add a new Tenant
              </button>
              <Modal
                open={createTenantModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                  <Box sx={style}>
                    <CreateTenantModal 
                      closeCreateTenantModalOpen={() => setCreateTenantModalOpen(false)}
                      addTenantToAdminPanel={(newTenantData) => {
                        console.log("new tenant data", newTenantData)
                        setTenantData([...tenantData, newTenantData])}}  
                    />
                  </Box>
              </Modal>
            </div>
          )
        }
      </div>
    )
}
export default TenantTab;