import React, {useState, useEffect} from "react"
import createTenantSchema from '../jsonFormSchema/createTenant/createTenantSchema.json';
import createTenantUISchema from '../jsonFormSchema/createTenant/createTenantUISchema.json';
import { JsonForms } from '@jsonforms/react';
import "../style.css";
import {
    materialRenderers,
    materialCells,
  } from '@jsonforms/material-renderers';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useCustomStorage } from '../hooks/useCustomStorage';
import { TENANT_API_URL } from "../constants/urls"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

const defaultTenant = {
    ip: ""
}
function CreateTenantModal({closeCreateTenantModalOpen, addTenantToAdminPanel}){
    const [authKey, setAuthKey] = useState("")
    const [tenantData, setTenantData] = useState(defaultTenant)
    const [tenantErrors, setTenantErrors] = useState({})
    const [authToken, setAuthToken] = useCustomStorage("acrm_auth_token")
    const [isLoading, setIsLoading] = useState(false) 
    const handleCreateTenant = () => {
        console.log("errors", tenantErrors)
        setIsLoading(true)
        fetch(`${TENANT_API_URL}?product=ACRM`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "x-api-key": authToken
            },
            body: JSON.stringify(tenantData)
        })
        .then((response) => {
            if(response.status !== 200){
            throw new Error("Something went wrong")
            }
            return response.json()
        })
        .then((response) => {
            setIsLoading(false)
            setAuthKey(response.auth_key)
            addTenantToAdminPanel(response)
            toast.success(`Successfully created tenant ${tenantData.tenantid}`)
            closeCreateTenantModalOpen()
        })
        .catch((err) => {
            console.log(err)
            toast.error("Something Went wrong")
            setIsLoading(false)
        })
    }
    return(
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                {
                    isLoading ? (
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div style={{width: "100%"}}>
                            <div style={{position: "absolute", right: "20px", top: "20px", cursor: "pointer"}} onClick={() => closeCreateTenantModalOpen()}><CloseIcon /></div>
                        {
                            authKey ? (
                                <div>
                                    <p>Tenant "{tenantData.tenantid}" has been created successfully. Store the following authentication key securely:</p>
                                    <strong style={{marginTop: "10px"}}>{authKey}</strong>
                                    <p>You will not be able to see this authentication key again.</p>
                                </div>
                            ) : (
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: "10px"}}>
                                        Create Tenant
                                    </Typography>
                                    <JsonForms
                                        schema={createTenantSchema}
                                        uischema={createTenantUISchema}
                                        data={tenantData}
                                        renderers={materialRenderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) =>{
                                            setTenantData(data)
                                            setTenantErrors(errors)
                                        }}
                                    />
                                    <button
                                        style={{
                                            margin: "2rem 0 2rem 0",
                                            fontSize: "1rem",
                                            backgroundColor: "#0083ce"
                                        }}
                                        onClick={() => {
                                            handleCreateTenant()
                                        }}
                                    >
                                        Create Tenant
                                    </button>
                                </div>
                            )
                        }
                        </div>
                    )
                }
            </div>
    )
}

export default CreateTenantModal;