import React from "react"
import { useEffect, useState } from "react"

import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import UserTab from "./UserTab";
import TenantTab from "./TenantTab";

function Admin(){
    const [value, setValue] = useState('users');
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
   
    return(
        <div
            style={{width: "85vw", display: "flex", justifyContent: "center"}}
        >
            <div style={{width: '100%', display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="Users" value="users" />
                            <Tab label="Tenants" value="tenants" />
                        </TabList>
                        </Box>
                        <TabPanel value="users">
                            <UserTab />
                        </TabPanel>
                        <TabPanel value="tenants">
                            <TenantTab />
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
          </div>
    )
}
export default Admin