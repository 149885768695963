import React from "react"
import { useEffect, useState } from "react"
import { CircularProgress } from '@mui/material';
import { divideSchema, mergeSchema } from "../utils/TransformSchema";
import { SCHEMA_CONFIG_API_URL } from "../constants/urls";
import Summary from "./Summary";

import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import QnA from "./QnA";
import { toast } from "react-toastify";
import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';
import UserTab from "./UserTab";
import TenantTab from "./TenantTab";

function SchemaModifer({tenantId}){
    const [isLoading, setIsLoading] = useState(true)
    const [value, setValue] = useState('qna');
    const [qnaData, setQnaData] = useState({})
    const [summaryData, setSummaryData] = useState({})
    const [errorData, setErrorData] = useState({qnaTables: {}, qnaRows: {}, summary: []})
    const [openUpdateSchemaPopup, setOpenUpdateSchemaPopup] = useState(false)
    const [hasChanges, setHasChanges] = useState(false)

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if(hasChanges){
                e.preventDefault();
                e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
            }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, [hasChanges]);

    const handleSetQnaData = (data) => {
        setHasChanges(true)
        setQnaData(data)
    }
    const handleSetSummaryData = (data) => {
        setHasChanges(true)
        setSummaryData(data)
    }
    
    const handleSetQnaErrorData = (errors, tableUuid, deleteTable = false) =>{
        if(deleteTable)
            setErrorData({
                ...errorData,
                qnaTables: {
                    ...errorData.qnaTables,
                    [tableUuid]: [],
                },
                qnaRows: {
                    ...errorData.qnaRows,
                    [tableUuid]: {},
                }
            })
        else
            setErrorData(
                {
                    ...errorData, 
                    qnaTables: {
                        ...errorData.qnaTables,
                        [tableUuid]: errors, 
                    }
                }
            )
    }
    const handleSetQnaRowsErrorData = (errors, tableUuid, rowName) => { 
        setErrorData({
                ...errorData, 
                qnaRows: {
                    ...errorData.qnaRows,
                    [tableUuid]: {
                        ...errorData.qnaRows[tableUuid],
                        [rowName]: errors, 
                    }, 
                }
            })
    }
    const handleUpdateSchema = () => {
        let errorFlag = false
        for(const tableUuid in errorData.qnaTables){
            if (errorData.qnaTables[tableUuid].length > 0){
                errorFlag = true
                break
            }
        }
        for (const tableUuid in errorData.qnaRows){
            for (const rowName in errorData.qnaRows[tableUuid]){
                if (errorData.qnaRows[tableUuid][rowName].length > 0){
                    errorFlag = true
                    break
                }
            }
        }
        if (errorFlag || errorData.summary.length > 0){
            console.log("error data", errorData)
            toast.error("There are errors in the schema. Please fix them before updating the schema.")
            return
        }
        try{
            const updatedSchema = mergeSchema(qnaData, summaryData)
            const auth_token = JSON.parse(localStorage.getItem("acrm_auth_token"))
            setIsLoading(true)
            fetch(`${SCHEMA_CONFIG_API_URL}?product=ACRM&tenantId=${tenantId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": auth_token
                },
                body: JSON.stringify(updatedSchema)
            }).then((response) => {
                if (response.status === 200){
                    setHasChanges(false)
                    toast.success("Schema updated successfully")
                }
                else
                    throw new Error("Something went wrong while trying to update the schema");
            }).finally(() => {
                setIsLoading(false)
            })
        } catch (error){
            console.log(error)
            toast.error(error.message)
        }
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        setIsLoading(true)
        setQnaData({})
        setSummaryData({})
        if(!tenantId) return
        const auth_token = JSON.parse(localStorage.getItem("acrm_auth_token"))
        fetch(`${SCHEMA_CONFIG_API_URL}?product=ACRM&tenantId=${tenantId}`, {
            method: "GET",
            headers: {
              "x-api-key": auth_token
            }
        }).then((response) => {
            if (response.status === 200)
                return response.json();
            else
                throw new Error("response status", response.status)
        }).then((response) => {
            const schemaData = divideSchema(response)
            setSummaryData(schemaData['summary'])
            setQnaData(schemaData['qna'])
        }).catch((error) => {
            toast.error(`Something went wrong while fetching the schema for Tenant ID ${tenantId}`)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [tenantId])
    return(
        <div
            style={{width: "85vw", display: "flex", justifyContent: "center"}}
        >
            {
                isLoading ? (
                    <div style={{display: "flex", alignItems: "center", height: "80vh"}}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div style={{width: '100%', display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                    <Tab label="QnA" value="qna" />
                                    <Tab label="Summary" value="summary" />
                                </TabList>
                                </Box>
                                <TabPanel value="qna">
                                    <QnA 
                                        qnaData={qnaData} 
                                        setQnaData={handleSetQnaData} 
                                        setQnaErrorData={handleSetQnaErrorData}
                                        setQnaRowErrorData={handleSetQnaRowsErrorData}
                                    />
                                </TabPanel>
                                <TabPanel value="summary">
                                    <Summary 
                                        summaryData={summaryData} 
                                        setSummaryData={handleSetSummaryData} 
                                        setSummaryErrorData={(errors) => setErrorData({...errorData, summary: errors})}
                                    />
                                </TabPanel>
                            </TabContext>
                        </Box>
                        {
                            (value === "qna" || value === "summary") && (
                                <div>
                                    <button
                                        style={{
                                            margin: "0 0 2rem 0",
                                            fontSize: "1rem",
                                            backgroundColor: "#0083ce"
                                        }}
                                        onClick={() => setOpenUpdateSchemaPopup(true)}
                                    >
                                        Update Schema 
                                    </button>
                                    <Dialog
                                        open={openUpdateSchemaPopup}
                                        onClose={() => setOpenUpdateSchemaPopup(false)}
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            Are you sure you want to update the schema?
                                        </DialogTitle>
                                        <DialogActions>
                                        <Button 
                                            onClick={() => {
                                                handleUpdateSchema()
                                                setOpenUpdateSchemaPopup(false)
                                            }}
                                        >
                                            Yes
                                        </Button>
                                        <Button onClick={() => setOpenUpdateSchemaPopup(false)} autoFocus>
                                            No
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            )
                        }
                    </div>
                )
            } 
          </div>
    )
}
export default SchemaModifer