import React from 'react';
import "../style.css";
import {
    materialRenderers,
    materialCells,
  } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import summarySchema from '../jsonFormSchema/summary/summarySchema.json';
import summaryUISchema from '../jsonFormSchema/summary/summaryUISchema.json';

function Summary({summaryData, setSummaryData, setSummaryErrorData}){
    return (
        <div className="summary">
            <JsonForms
                schema={summarySchema}
                uischema={summaryUISchema}
                data={summaryData}
                renderers={materialRenderers}
                cells={materialCells}
                onChange={({ data, errors }) =>{
                    setSummaryErrorData(errors)
                    setSummaryData(data)
                }}
            />
        </div>
    )
}
export default Summary;