import { useEffect, useState } from "react"

import { HEALTHCHECK_API_URL } from "../constants/urls"
import { useCustomStorage } from "../hooks/useCustomStorage"
import { CircularProgress } from "@mui/material"
import "../style.css"
import { toast } from "react-toastify"

function AuthTokenInput({ isAuthorized, setIsAuthorized}) {
  const [inputValue, setInputValue] = useState("")
  const [displayMsg, setDisplayMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [maskedToken, setMaskedToken] = useState("")
  const [authToken, setAuthToken] = useCustomStorage("acrm_auth_token")

  const handleInputChange = (e) => {
    if (e.target.value.length > 50) return
    setInputValue(e.target.value)
  }

  useEffect(() => {
    setMaskedToken(
      authToken ? authToken.length > 5
        ? "*".repeat(authToken.length - 5) + authToken.slice(-5)
        : authToken
      : "No token saved yet!"
    )
  }, [authToken])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setAuthToken(inputValue)
    if (!inputValue) {
      setIsLoading(false)
      setDisplayMsg("Enter token to authorize!")
      setIsAuthorized(false)
      return
    }
    setDisplayMsg("Fetching authorization status...")
    setIsLoading(true)
    fetch(HEALTHCHECK_API_URL, {
      method: "GET",
      headers: {
        "x-api-key": inputValue
      }
    }).then((response) => {
        if (response) {
          if (response.status === 200) {
            // toast.success("You are authorized!")
            setDisplayMsg("You are authorized!")
            window.location.href = "/"
            setIsAuthorized(true)
          } else if (response.status === 403) {
            setDisplayMsg("The token is invalid!")
            setIsAuthorized(false)
          } else {
            setDisplayMsg("Something went wrong! Enter token again!")
            setIsAuthorized(false)
          }
        }
      })
      .catch((error) => {
        console.error(error)
        setDisplayMsg("Server error, enter token again!")
        toast.error("Server error, enter token again!")
        setIsAuthorized(false)
      }).finally(() => {
        setIsLoading(false)
      })
    setInputValue("")
  }

  return (
    <main>
      {
        isLoading ? (
          <div
            style={{height: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}
          >
            <CircularProgress />
            <div style={{marginTop: "20px"}}>
              Fetching Authorization Status...
            </div>
          </div>
        ) : (
          <div>
            <div>
              <form 
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Enter Authorization token here..."
                  style={{
                    padding: "0.5rem",
                    outline: "none",
                    border: "1px solid #ccc",
                    borderRadius: "0.25rem",
                    marginRight: "0.5rem",
                    minWidth: "80%",
                    fontSize: "1rem"
                  }}
                />
                <button
                  style={{
                    padding: "0.5rem",
                    backgroundColor: "#4CAF50"
                  }}
                  type="submit">
                  Submit
                </button>
              </form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p>
                  Current Auth Token: <strong>{maskedToken}</strong>
                </p>
                <p>{displayMsg}</p>
              </div>
            </div>
          </div>
        )
      }
    </main>
  )
}
export default AuthTokenInput