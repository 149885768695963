import react from 'react';
import { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import {
    materialRenderers,
    materialCells,
  } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import qnaRowSchema from '../jsonFormSchema/qna/qnaTableRows/qnaRowsSchema.json';
import qnaRowUISchema from '../jsonFormSchema/qna/qnaTableRows/qnaRowsUISchema.json';
import "../style.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import generateRandomString from '../utils/GenerateRandomString';

function QnaRows({qna, tableIndex, qnaData, setQnaData, setQnaRowErrorData}){
    const [openDeleteRowPopup, setOpenDeleteRowPopup] = useState(false)
    const [deleteRowIndex, setDeleteRowIndex] = useState(-1)
    const handleAddRow = () => {
        setQnaData({
            "QnA": [
                ...qnaData["QnA"].slice(0, tableIndex),
                {
                    ...qnaData["QnA"][tableIndex],
                    "Rows": [
                        ...qnaData["QnA"][tableIndex]["Rows"],
                        {
                            "uuid": generateRandomString(),
                            "isNewRow": true,
                        }
                    ]
                },
                ...qnaData["QnA"].slice(tableIndex + 1)
            ]
        })
    }
    const handleDeleteRow = (index) => {
        setQnaRowErrorData([], qna?.['uuid'], qna?.['Rows'][index]['uuid'])
        setQnaData(
            {
                "QnA": [
                    ...qnaData["QnA"].slice(0, tableIndex),
                    {
                        ...qnaData["QnA"][tableIndex],
                        "Rows": [
                            ...qnaData["QnA"][tableIndex]["Rows"].slice(0, index),
                            ...qnaData["QnA"][tableIndex]["Rows"].slice(index + 1)
                        ]
                    },
                    ...qnaData["QnA"].slice(tableIndex + 1)
                ]
            }
        )
        setDeleteRowIndex(-1)
    }
    return(
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            {
                openDeleteRowPopup && (
                    <Dialog
                        open={openDeleteRowPopup}
                        onClose={() => setOpenDeleteRowPopup(false)}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Delete Row "{qna?.["Rows"][deleteRowIndex]["FieldKeyName"]}"?
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the row "{qna?.["Rows"][deleteRowIndex]["FieldKeyName"]}"?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button 
                            onClick={() =>{
                                setOpenDeleteRowPopup(false)
                                handleDeleteRow(deleteRowIndex)
                            }}
                        >
                            Yes
                        </Button>
                        <Button onClick={() => setOpenDeleteRowPopup(false)} autoFocus>
                            No
                        </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
            {
                qna?.["Rows"]?.map((row, index) => {
                    return(
                        <div key={row?.['uuid']} style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: "90%"}}>
                            <Accordion 
                                TransitionProps={{ unmountOnExit: !row?.['isNewRow'] }} 
                                style={{
                                    padding: "0 1rem",
                                    width: "89%",
                                    paddingBottom: "0",
                                    backgroundColor: "#FAF9F6",
                                    marginBottom: "0.5rem"
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    style={{height: "50px"}}
                                >
                                <Typography>{row?.['FieldKeyName']}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{paddingBottom: "2rem"}}
                                >
                                    <JsonForms
                                        schema={qnaRowSchema}
                                        uischema={qnaRowUISchema}
                                        data={row}
                                        renderers={materialRenderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) =>{
                                            setQnaRowErrorData(errors, qna?.['uuid'], row?.['uuid'])
                                            setQnaData(
                                                {
                                                    "QnA": [
                                                        ...qnaData["QnA"].slice(0, tableIndex),
                                                        {
                                                            ...qnaData["QnA"][tableIndex],
                                                            "Rows": [
                                                                ...qnaData["QnA"][tableIndex]["Rows"].slice(0, index),
                                                                data,
                                                                ...qnaData["QnA"][tableIndex]["Rows"].slice(index + 1)
                                                            ]
                                                        },
                                                        ...qnaData["QnA"].slice(tableIndex + 1)
                                                    ]
                                                }
                                            )
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <button
                                style={{
                                    width: "6%",
                                    backgroundColor: "#f44336",
                                    height: "50px",
                                    padding: "0.2rem 0"
                                }}
                                onClick={() => {
                                    setDeleteRowIndex(index)
                                    setOpenDeleteRowPopup(true)
                                }}
                            >
                                <DeleteOutlinedIcon />
                            </button>
                        </div>
                    )
                })
            }
            <button
                style={{
                    marginTop: "1rem",
                    width: "90%",
                    fontSize: "1rem",
                    backgroundColor: "#4CAF50"
                }}
                onClick={handleAddRow}
            >
                Add Row
            </button>
        </div>
    )
}
export default QnaRows;