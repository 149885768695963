export function useCustomStorage(key, defaultValue) {
    // Define the storage instance based on the specified area

    // Get the stored value or use the default value
    const storedValue = localStorage.getItem(key);
    const initialValue = storedValue ? JSON.parse(storedValue) : defaultValue;

    // Define a function to set the value in localStorage
    const setValue = (newValue) => {
        const valueToStore = JSON.stringify(newValue);
        localStorage.setItem(key, valueToStore);
    };

    return [initialValue, setValue];
}