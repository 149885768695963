import React, { useEffect, useState } from 'react';
import "../style.css";
import {
    materialRenderers,
    materialCells,
  } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import qnaTableSchema from '../jsonFormSchema/qna/qnaTableSchema.json';
import qnaTableUISchema from '../jsonFormSchema/qna/qnaTableUISchema.json';
import QnaRows from './QnaRows';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import generateRandomString from '../utils/GenerateRandomString';


function QnA({qnaData, setQnaData, setQnaErrorData, setQnaRowErrorData, deleteQnaErrorData}){
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(-1)
    const [isReadMore, setIsReadMore] = useState(false)

    const handleAddTable = () => {
        setQnaData({
            "QnA": [
                ...qnaData["QnA"],
                {
                    "isNewTable": true,
                    "uuid": generateRandomString(),
                    "Rows": []
                }
            ]
        })
    }
    const handleDeleteTable = (index) => {
        setQnaErrorData([], qnaData["QnA"][index]["uuid"], true)
        setQnaData(
            {
                "QnA": [
                    ...qnaData["QnA"].slice(0, index),
                    ...qnaData["QnA"].slice(index + 1)
                ]
            }
        )
        setDeleteIndex(-1)
    }
    return (
        <div>
            <h2 style={{marginTop: "0", marginBottom: "0", width: "100", display: "flex", justifyContent: "center"}}> Instructions: </h2>
            <ul>
                <li>To modify an existing table, click on its name and change the required fields.</li>
                <li>Click on the "Add Table" button in the bottom to add a new table.</li>
                <li>Inside a table, click on the "Add Row" button to add a new row.</li>
                <li>Once all changes are made, click on the "Update Schema" button to bring them into effect. This action is <strong>irreversible</strong>. </li>
                <li>
                    <u>Artificial Joins</u> are required to query fields that are not searchable by default in an object as the data comes from another object but can be found in another object. 
                    <br />
                    <u>For example:</u> The <i>ContactPerson</i> object's schema has a field called <i>CompanyName</i> even though no such field actually exists in the object. 
                    {
                        isReadMore && (
                            <>
                                However, it is possible to get the Company's name from the <i>Company</i> object. In such cases, artificial joins can be used to automatically query the 
                                <i>Company</i> object to fetch the Company's Name. Here, the <i>ContactPerson</i> object will have a <i>CompanyName</i> field whose details will be:
                                <ul>
                                    <li><u>Artificial Join Table name:</u> Company (Table Display Name)</li>
                                    <li><u>Artificial Join Field name:</u> CompanyName (Field Display Name of the field you want to access from the Join table)</li>
                                    <li><u>ColumnAliases will have one field:</u> Company.CompanyName (Format: &lt;Artificial Join Table name&gt;.&lt;Artificial Join Field name&gt;)</li>
                                </ul>
                            </>
                        )
                    }
                    <span 
                        onClick={()=>setIsReadMore(!isReadMore)}
                        style={{color: "#0083ce", cursor: "pointer"}}
                    >
                        {isReadMore ? (<>Read Less</>) : (<>...Read More</>)}
                    </span>
                </li>
            </ul>
            {
                openDeletePopup && (
                    <Dialog
                        open={openDeletePopup}
                        onClose={() => setOpenDeletePopup(false)}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Delete table "{qnaData["QnA"]?.[deleteIndex]?.["TableKeyName"]}"?
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the table "{qnaData["QnA"]?.[deleteIndex]?.["TableKeyName"]}"?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button 
                            onClick={() =>{
                                handleDeleteTable(deleteIndex)
                                setOpenDeletePopup(false)
                            }}
                        >
                            Yes
                        </Button>
                        <Button onClick={() => setOpenDeletePopup(false)} autoFocus>
                            No
                        </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
            {
                qnaData?.["QnA"]?.map((qna, index) => {
                    return (
                        <div 
                            key={qna?.["uuid"]}
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "1rem",
                            }}
                        >
                            <Accordion 
                                TransitionProps={{ unmountOnExit: !qna?.['isNewTable'] }} 
                                style={{
                                    padding: "0 1rem",
                                    width: "90%",
                                    paddingBottom: "0"
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    style={{height: "50px"}}
                                >
                                <Typography sx={{fontWeight: "bold"}}>{qna?.['TableKeyName']}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{paddingBottom: "2rem"}}
                                >
                                    <JsonForms
                                        schema={qnaTableSchema}
                                        uischema={qnaTableUISchema}
                                        data={qna}
                                        renderers={materialRenderers}
                                        cells={materialCells}
                                        onChange={({ data, errors }) =>{
                                            setQnaErrorData(errors, qna["uuid"])
                                            if (data !== qnaData["QnA"][index]) { // used to prevent infinite rerenders
                                                const updated_data = {
                                                    "QnA": [
                                                        ...qnaData["QnA"].slice(0, index),
                                                        {
                                                            ...qnaData["QnA"][index],
                                                            ...data
                                                        },
                                                        ...qnaData["QnA"].slice(index + 1)
                                                    ]
                                                }
                                                setQnaData(updated_data)
                                            }
                                        }}
                                    />
                                    <h3 style={{fontWeight:"100"}}>Rows *</h3>
                                    <QnaRows 
                                        qna={qna} 
                                        tableIndex={index} 
                                        qnaData={qnaData} 
                                        setQnaData={setQnaData} 
                                        setQnaRowErrorData={setQnaRowErrorData}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <button
                                style={{
                                    width: "5%",
                                    backgroundColor: "#f44336",
                                    height: "50px",
                                    padding: "0.2rem 0",
                                }}
                                onClick={() => {
                                    setDeleteIndex(index)
                                    setOpenDeletePopup(true)
                                }}
                            >
                                <DeleteOutlinedIcon />
                            </button>
                        </div>
                    )
                })
            }
            <button
                style={{
                    marginTop: "1rem",
                    width: "100%",
                    fontSize: "1rem",
                    backgroundColor: "#4CAF50"
                }}
                onClick={handleAddTable}
            >
                Add Table
            </button>
        </div>
    )
}
export default QnA;